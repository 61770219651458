import {
  Header,
  Image,
  NavGroup,
  ProfileSideBar,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import Assets from "../../../../assets/Assets";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import profileStyle from "./packageDetailsLayout.module.scss";
import usePackageDetailsLayout from "./usePackageDetailsLayout";

const PackageDetailsLayout = () => {
  const { activeProfile, isFetching, packageData, navigation } =
    usePackageDetailsLayout();

  const { navigations, globalState } = useCommonLayout();

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(packageData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={`${profileStyle.left_sidebar_sticky} `}
                >
                  <div className={`pro-px-4 pro-pt-5 left-sidebar`}>
                    <div
                      className={`pro-pt-2 pro-pb-1 pro-border-bottom pro-mb-4 `}
                    >
                      <Link
                        to={`/packages`}
                        className="pro-back-btn pro-items-center pro-d-flex pro-items-center pro-mb-2"
                      >
                        <span className="material-symbols-outlined x4">
                          {" "}
                          keyboard_arrow_left{" "}
                        </span>
                        Back
                      </Link>
                      <h2 className={profileStyle.left_sidebar_title}>
                        {packageData?.data?.name ?? ""}
                      </h2>
                    </div>

                    <div className={`${profileStyle.left_sidebar_sticky_top} `}>
                      <div className={`${profileStyle.profile} pro-mb-4 `}>
                        <Image
                          src={`${
                            packageData?.data?.package_image_url ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      {packageData?.data?.banner_title?.length !== 0 && (
                        <div
                          className={`pro-text-center ${profileStyle.left_sidebar_subtitle}`}
                        >
                          <h3>
                            {packageData?.data?.banner_title?.map(
                              (item, index) => (
                                <span
                                  className={
                                    item?.is_colour ? `pro-text-success` : ``
                                  }
                                >
                                  {item?.content_title}
                                </span>
                              )
                            )}
                          </h3>
                        </div>
                      )}
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <div className="pro-d-flex  pro-justify-between">
                        <h6 className="pro-ttl h6 fw-semibold pro-mb-3 pro-fw-bold">
                          {" "}
                          Basic Details
                        </h6>
                        <i>
                          <img src={Assets.EDIT} />
                        </i>
                      </div>
                      <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                        <strong>Types</strong>
                        <div className={`${profileStyle.dtls} pro-mt-1 `}>
                          {packageData?.data?.package_type_text ?? ""}
                        </div>
                      </div>
                      <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                        <strong>Description</strong>
                        <div className={`${profileStyle.dtls} pro-mt-1`}>
                          {packageData?.data?.description ?? ""}
                        </div>
                      </div>
                      {packageData?.data?.package_benefit?.length !== 0 && (
                        <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                          <strong>Benefits</strong>
                          <div className={`${profileStyle.dtls} pro-mt-1`}>
                            <ul className={Style.list_root}>
                              {packageData?.data?.package_benefit?.map(
                                (file, index) => (
                                  <li
                                    key={file?.id}
                                    className={`${Style.list_item}`}
                                  >
                                    {/* <span className={Style.count}>{`${String(
                                      index + 1
                                    ).padStart(2, 0)}.`}</span> */}
                                    <span>
                                      {file?.title}
                                      <a className="pro-pnt">
                                        {file?.file_path}
                                      </a>
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* <OffCanvasLayout
            show={ProfileState?.showAddModal}
            handleClose={closeModal}
            title={`${"Update Student Basic Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <AddStudents
              isStickyFooter
              refetch={refetch}
              closeModal={closeModal}
            />
          </OffCanvasLayout> */}

          {/* <ModalLayout
            show={ProfileState?.showDeleteModal}
            handleClose={() => handleDeleteModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={popUpMessage}
                cancelText={"No"}
                cancelAction={handleDeleteModal}
                submitText={"Yes"}
                submitAction={() => setStatusUpdate(true)}
                isRight={true}
              />
            </div>
          </ModalLayout> */}

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <div className={` tab_wrapper`}>
                <NavGroup
                  navigations={navigation}
                  type={"type-2"}
                  Link={Link}
                />
                <Outlet context={{ activeProfile }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default PackageDetailsLayout;
