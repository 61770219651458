import { useGetPrivacyPolicyDataQuery } from "../../../store/queries/cms";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { updateConfig } from "../../../store/slices/Cms/privacyPolicySlice";
import { useEffect } from "react";

const usePrivacyPolicy = () => {
  const dispatch = useDispatch();
  const privacyPolicyState = useSelector((state) => state.privacyPolicy);

  const { data: privacyPolicyData = {}, refetch } =
    useGetPrivacyPolicyDataQuery({});

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.description = privacyPolicyData?.data?.content;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyPolicyData?.data?.content]);

  const handleOpenEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.editModal = true;
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.editModal = false;
      })
    );
  };
  return {
    privacyPolicyData,
    privacyPolicyState,
    refetch,
    handleCloseModal,
    parse,
    handleOpenEditModal,
  };
};

export default usePrivacyPolicy;
