import React from "react";
import UseFaqForm from "./useFaqForm";
import Select from "react-select";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const FaqForm = ({ refetch, closeModal }) => {
  const {
    basicData,
    moduleBasicData,
    FaqTypes,
    formik,
    faqState,
    handleChangeType,
    getFieldError,
  } = UseFaqForm({ refetch, closeModal });
  return (
    <>
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="type" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type
          </label>
          <Select
            id="type"
            name="type"
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik?.errors?.type && formik?.touched?.type && "error"
            }`}
            classNamePrefix="pro-input"
            options={FaqTypes}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={FaqTypes?.filter(
              (item) =>
                item?.value?.toString() === formik?.values?.type?.toString()
            )}
            onBlur={formik.handleBlur("type")}
            onChange={(value) => handleChangeType(value)}
            menuPlacement="auto"
          />
          {formik.touched.type && formik.errors.type && (
            <div className="error-text">{formik.errors.type}</div>
          )}
        </div>
      </div>

      {(formik?.values?.type === "1" || formik?.values?.type === 1) && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="module_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Module
            </label>
            <Select
              id="module_id"
              name="module_id"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.errors?.module_id &&
                formik.touched?.module_id &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={moduleBasicData?.data}
              getOptionLabel={(option) => option?.module_name}
              getOptionValue={(option) => option?.id}
              value={moduleBasicData?.data?.filter(
                (item) =>
                  item?.id?.toString() === formik?.values?.module_id?.toString()
              )}
              onBlur={formik.handleBlur("module_id")}
              onChange={(value) =>
                formik?.setFieldValue("module_id", value?.id || null)
              }
              menuPlacement="auto"
            />
            {formik?.touched?.module_id && formik?.errors?.module_id && (
              <div className="error-text">{formik?.errors?.module_id}</div>
            )}
          </div>
        </div>
      )}

      {(formik?.values?.type === "2" || formik?.values?.type === 2) && (
        <div className="col pro-mb-4">
          <div className="input-wrap">
            <label
              htmlFor="package_id"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Package
            </label>
            <Select
              id="package_id"
              name="package_id"
              placeholder={"Select"}
              className={`pro-input lg  ${
                formik?.errors?.package_id &&
                formik?.touched?.package_id &&
                "error"
              }`}
              classNamePrefix="pro-input"
              options={basicData?.data?.package_type}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              value={basicData?.data?.package_type?.filter(
                (item) =>
                  item?.id?.toString() ===
                  formik?.values?.package_id?.toString()
              )}
              onBlur={formik.handleBlur("package_id")}
              onChange={(value) =>
                formik?.setFieldValue("package_id", value?.id || null)
              }
              menuPlacement="auto"
            />
            {formik?.touched?.package_id && formik?.errors?.package_id && (
              <div className="error-text">{formik?.errors?.package_id}</div>
            )}
          </div>
        </div>
      )}

      <Input
        label={"Question*"}
        type="text"
        id="question"
        name="question"
        className={`pro-input lg ${getFieldError("question") && " error"}`}
        {...formik.getFieldProps("question")}
        error={getFieldError("question")}
        errorMessage={getFieldError("question")}
      />

      <Input
        label={"Answer*"}
        type="text"
        id="answer"
        name="answer"
        className={`pro-input lg ${getFieldError("answer") && " error"}`}
        {...formik.getFieldProps("answer")}
        error={getFieldError("answer")}
        errorMessage={getFieldError("answer")}
      />

      {faqState?.is_edit && (
        <div className="pro-toggle">
          <div className="pro-toggle-box">
            <input
              id="status"
              name="status"
              type="checkbox"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? 0
                );
              }}
            />
            <span></span>
          </div>
          {/* <label>Status</label> */}
        </div>
      )}
      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={closeModal}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {faqState?.is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </>
  );
};

export default FaqForm;
