import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { exportCustomer, updateConfig } from "../../../store/slices/customer/customerSlice";
import { addDays } from "date-fns";
import { useState, useMemo, useEffect } from "react";
import {
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/global";
import { getUserEditData } from "../../../store/slices/user/userSlice";
import Assets from "../../../assets/Assets";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./user.module.scss";
import { useGetCustomerListDataQuery } from "../../../store/queries/customer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const useCustomers = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.customer);
  const { showEditModal } = useSelector((state) => state.global);
  const { showCreateModal } = useSelector((state) => state.customer);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const { data: formData = {} } = useGetBasicDataQuery();
  const navigate = useNavigate();
  const [filterShow, setFilterShow] = useState(false);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetCustomerListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState?.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    status: menuState.status,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status_label: (field, data) => {
        // Determine the class based on the status
        const statusValue = data?.status_label?.toLowerCase();
        let statusClass = "";
        switch (statusValue) {
          case "requested":
            statusClass = "badge-warning-outline";
            break;
          case "active":
            statusClass = "badge-success-outline";
            break;
          case "deleted":
            statusClass = "badge-info-outline";
            break;
          case "rejected":
            statusClass = "badge-danger-outline";
            break;
          default:
            statusClass = ""; // Default class if none of the above match
            break;
        }

        return (
          <p className="pro-mb-0">
            <span className={`pro-badge ${statusClass}`}>{data[field]}</span>
          </p>
        );
      },
      name: (field, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
              onClick={() => {
                navigate({
                  pathname: `/user-details/${data?.id}`,
                });

                sessionStorage.setItem("active", `${data?.id}`);
              }}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[field].image ?? Assets.GENDER_IMAGE}
                  width={16}
                  height={16}
                  alt={data[field]}
                />
              </div>
              <span className="pro-ms-1 pro-align-self-center pro-pnt">
                {data[field]}
              </span>
            </div>
          </>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  // const toggleStatusById = (status, id) => {
  //   let formData = new FormData();
  //   formData.append("_method", "PUT");
  //   formData.append("user_id", id);
  //   formData.append("status", status === 1 ? 0 : 1);

  //   updateUserStatus(formData);
  //   dispatch(
  //     user.util.updateQueryData(
  //       "getUserListData",
  //       {
  //         sort_by: menuState.sortBy,
  //         sort_order: menuState.sortOrder,
  //         search: menuState.search,
  //         per_page: menuState.currentPageSize,
  //         page: menuState?.currentPage,
  //         filter: menuState?.currentFilter,
  //         start: getFormatedDate(menuState?.start),
  //         end: getFormatedDate(menuState?.end),
  //       },
  //       (cacheEntry) => {
  //         let currentCache = { ...current(cacheEntry) };
  //         const newResult = currentCache.data.data?.data?.map((res) =>
  //           res.user_id === id
  //             ? {
  //                 ...res,
  //                 status: status === 1 ? 0 : 1,
  //               }
  //             : res
  //         );
  //         currentCache = {
  //           ...currentCache,
  //           data: {
  //             ...currentCache.data,
  //             data: {
  //               ...currentCache.data.data,
  //               data: newResult,
  //             },
  //           },
  //         };
  //         return currentCache;
  //       }
  //     )
  //   );
  // };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (data) => {
    dispatch(getUserEditData(data?.[0]));

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        state.userEditData = {};
        // state.selectedItemsDetails = ""
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };
  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/customer/");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const visibleFields = mainData?.data?.field ? Object?.keys(mainData?.data?.field)?.filter(key => !mainData?.data?.field[key]?.hidden) : [];

  const handleExportClick = () => {
    let data = {
      fields: visibleFields,
      start_date: menuState.start_date,
    end_date: menuState.end_date,
    status: menuState.status ?? "",
    search: menuState.search,
    };
    exportCustomer(data).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}_excel.xlsx`
        );    
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else if (response?.status === 403) {
        toast.warning("Invalid Data");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    mainData,
    isLoading,
    isFetching,
    hasEditPermission,
    hasViewPermission,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    refetch,
    formData,
    showCreateModal,
    showEditModal,
    date,
    filters,
    handleDateRangeChange,
    handleFilter,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleDashboardRedirect,
    filterShow,
    setFilterShow,
    handleExportClick
  };
};

export default useCustomers;
