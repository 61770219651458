import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { exportCampaign, updateConfig } from "../../../store/slices/Campaigns/campaignsSlice.js";
import { useState, useMemo, useEffect } from "react";
import {
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/global";
import { useGetCampaignDataQuery } from "../../../store/queries/campaigns";
import { toast } from "react-toastify";
import styles from "./campaigns.module.scss";
import { current } from "@reduxjs/toolkit";
import moment from "moment";

const useCampaigns = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.campaigns);
  const { showEditModal } = useSelector((state) => state.global);
  const { showCreateModal, is_edit } = useSelector((state) => state.campaigns);
  const { data: formData = {} } = useGetBasicDataQuery();
  const [filterShow, setFilterShow] = useState(false);

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetCampaignDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState?.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
    utm_medium: menuState.utm_medium,
    utm_source: menuState.utm_source,
    utm_term: menuState.utm_term,
    utm_campaign: menuState.utm_campaign,
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  // const hasEditPermission = useMemo(() => {
  //   let permission = mainData?.data?.permission?.filter((p) =>
  //     Object.keys(p).includes("can_update")
  //   );
  //   return permission?.[0]?.can_update ?? 0;
  //   // eslint-disable-next-line
  // }, [mainData]);

  // const hasViewPermission = useMemo(() => {
  //   let permission = mainData?.data?.permission?.filter((p) =>
  //     Object.keys(p).includes("can_view")
  //   );
  //   return permission?.[0]?.can_view ?? 0;
  //   // eslint-disable-next-line
  // }, [mainData]);

  // const hasChangeStatusPermission = useMemo(() => {
  //   let permission = mainData?.data?.permission?.filter((p) =>
  //     Object.keys(p).includes("can_change_status")
  //   );
  //   return permission?.[0]?.can_change_status ?? 0;
  //   // eslint-disable-next-line
  // }, [mainData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        state.userEditData = {};
        state.clearSelection = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const visibleFields = mainData?.data?.field
    ? Object?.keys(mainData?.data?.field)?.filter(
        (key) => !mainData?.data?.field[key]?.hidden
      )
    : [];

  const handleExportClick = () => {
    let data = {
      fields: visibleFields,
      start_date: menuState.start_date,
      end_date: menuState.end_date,
      utm_medium: menuState.utm_medium,
      utm_source: menuState.utm_source,
      utm_term: menuState.utm_term,
      utm_campaign: menuState.utm_campaign,
      search: menuState.search,
    };
    exportCampaign(data).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}_excel.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else if (response?.status === 403) {
        toast.warning("Invalid Data");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  return {
    mainData,
    isLoading,
    isFetching,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    activeFilter: menuState?.currentFilter,
    formData,
    showCreateModal,
    showEditModal,
    // showDeleteConfirm,
    is_edit,
    filterShow,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    // handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    setFilterShow,
    updateTableFields,
    handleExportClick,
  };
};

export default useCampaigns;
