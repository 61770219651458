import React, { useState } from "react";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const CampaignFilterForm = ({ formik, handleReset, formData }) => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const minDate = today.toISOString().split("T")[0];

  const [startDateClass, setStartDateClass] = useState("");
  const [endDateClass, setEndDateClass] = useState("");

  // Handle change for the start date picker
  const handleStartDateChange = (e) => {
    formik.handleChange(e);
    setStartDateClass(e.target.value ? "date-selected" : "");
  };

  // Handle change for the end date picker
  const handleEndDateChange = (e) => {
    formik.handleChange(e);
    setEndDateClass(e.target.value ? "date-selected" : "");
  };

  const utmSource = [
    { label: "Google", value: "Google" },
    { label: "Meta", value: "Meta" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: "Organic", value: "Organic" },
  ];
  const utmMedium = [
    { label: "Google_Ads", value: "Google_Ads" },
    { label: "Google_Organic", value: "Google_Organic" },
    { label: "Meta_Ads", value: "Meta_Ads" },
    { label: "LinkedIn_Ads", value: "LinkedIn_Ads" },
  ];
    

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Utm Source
        </label>
        <Select
          id="utm_source"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="utm_source"
          options={utmSource}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={utmSource?.filter((m) => formik.values.utm_source === m.value)}
          onChange={(value) => formik.setFieldValue("utm_source", value?.value)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Utm Medium
        </label>
        <Select
          id="utm_medium"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          name="utm_medium"
          options={utmMedium}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={utmMedium?.filter((m) => formik.values.utm_medium === m.value)}
          onChange={(value) => formik.setFieldValue("utm_medium", value?.value)}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Start Date
        </label>
        <input
          className={`pro-input lg  ${startDateClass}`}
          type="date"
          name="start_date"
          max={minDate}
          onChange={handleStartDateChange}
          value={formik.values.start_date}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          End Date
        </label>
        <input
          className={`pro-input lg  ${endDateClass}`}
          type="date"
          name="end_date"
          // max={new Date().toISOString().split("T")[0]}
          min={formik.values.start_date}
          onChange={handleEndDateChange}
          value={formik.values.end_date}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        UTM Campaign
        </label>
        <input
          className={`pro-input lg`}
          type="text"
          name="utm_campaign"
          onChange={(e) => formik.setFieldValue("utm_campaign", e.target.value)}
          value={formik.values.utm_campaign}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        UTM Term
        </label>
        <input
          className={`pro-input lg`}
          type="text"
          name="utm_term"
          onChange={(e) => formik.setFieldValue("utm_term", e.target.value)}
          value={formik.values.utm_term}
        />
      </div>
      

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleReset()}
        >
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CampaignFilterForm;
