import {
  Button,
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { ReactSVG } from "react-svg";
import Assets from "../../../../assets/Assets";
import EmptyData from "../../../Global/EmptyData";
import LearningThumbShimmer from "../../../Global/Shimmers/LearningThumbShimmer";
import Style from "./myLearning.module.scss";
import useMyLearning from "./useMyLearning";
import ReactPlayer from "react-player";

const MyLearning = () => {
  const { mainData, Player, closePlayerModal, handleWatchVideo } =
    useMyLearning();
  return (
    <>
      <HeadingGroup title={"My Learning"} className={`pro-mb-4`} />
      <div className={` pro-pt-3 pro-pb-6`}>
        {mainData?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div className={`row row-cols-3  ${Style.video_grid}`}>
            {!mainData?.data ? (
              <LearningThumbShimmer />
            ) : (
              <>
                {mainData?.data?.map((item) => (
                  <div className="col pro-mb-4">
                    <div
                      key={item.id}
                      className={` pro-d-flex pro-p-0 ${Style.video_grid_list}`}
                    >
                      <figure className={`${Style.video_grid_thumbnail} shimmer`}>
                        {item.thumbnail && (
                          <img src={item.thumbnail} alt={"item thumbnail"} />
                        )}
                      </figure>
                      <div className={`pro-p-4 ${Style.video_grid_dtls}`}>
                        <h6>{item.name}</h6>
                        <p>{item.description}</p>
                        <span className="pro-badge badge-grey pro-rounded-2 pro-py-1">
                          Sales Mastery Program
                        </span>
                        <div
                          className={`${Style.watch_video_wrap} pro-d-flex pro-justify-end pro-items-center pro-pt-2  pro-gap-2`}
                        >
                          {/* <span className={Style.time}>01:59:00</span> */}
                          <Button
                            onClick={() => handleWatchVideo(item)}
                            className="pro-btn pro-btn-outline  "
                          >
                            Watch Now
                            <ReactSVG src={Assets.ICON_PLAY} />
                          </Button>
                        </div>
                        {/* <div className={Style.video_loader}><span style={{width:'30%'}}></span></div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      <ModalLayout
        show={Player.modal}
        handleClose={closePlayerModal}
        title={Player?.title}
        closeIcon={
          <span className=" close material-symbols-outlined">close</span>
        }
        backdrop="static"
        className={`video-modal`}
      >
        <div className="pro-p-4 inner">
          <div className="video-wrap">
            {/* <iframe
              title="video player"
              src={Player.video}
              width={"100%"}
              height={"450px"}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            /> */}

            <ReactPlayer
              url={Player.video}
              width="100%"
              height={"auto"}
              controls={true}
              playing={true}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous",
                  },
                },
              }}
            />
          </div>
        </div>
      </ModalLayout>
    </>
  );
};

export default MyLearning;
