import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddCoupon from "./useAddCoupon";
import RangeSlider from "../../../../Global/RangeSlider";

const AddCoupon = ({ refetch, closeModal, programId }) => {
  const { getFieldError, formik, is_edit, handleButtonClick } = useAddCoupon({
    closeModal,
    refetch,
    programId,
  });

  return (
    <div className={`row`}>
      <div className="col pro-mb-4">
        <div className="pro-d-flex pro-gap-1">
          <button
            onClick={() => handleButtonClick("b1")}
            className={`pro-btn ${
              formik?.values?.type === 2 ? "pro-btn-primary" : "pro-btn-outline"
            }`}
          >
            Agent Discount
          </button>
          <button
            onClick={() => handleButtonClick("b2")}
            className={`pro-btn ${
              formik?.values?.type === 1 ? "pro-btn-primary" : "pro-btn-outline"
            }`}
          >
            Company Discount
          </button>
        </div>
        {formik.touched?.type && formik.errors?.type && (
          <div className="error-text">{formik.errors?.type}</div>
        )}
      </div>
      <div className="pro-w-100 pro-mb-0 range-slider">
        <RangeSlider
          min={0}
          max={formik?.values?.type === 2 ? 20 : 30}
          initialValue={formik?.values?.discount_percentage}
          formik={formik}
        />
      </div>

      <div className="pro-w-100 pro-mb-3">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description
        </label>
        <textarea
          type="text"
          id="description"
          rows={3}
          cols={40}
          name="account_number"
          className={`pro-input lg ${getFieldError("description") && " error"}`}
          value={formik?.values?.description}
          onChange={(e) => formik?.setFieldValue("description", e.target.value)}
        />
        {formik.touched?.description && formik.errors?.description && (
          <div className="error-text">{formik.errors?.description}</div>
        )}
      </div>

      <Input
        label={"Code*"}
        type="text"
        id="code"
        name="code"
        className={`pro-input lg ${getFieldError("code") && " error"}`}
        {...formik.getFieldProps("code")}
        error={getFieldError("code")}
        errorMessage={getFieldError("code")}
      />
      {formik.values.type === 1 && (
        <Input
          label={"Available Count*"}
          onKeyDown={(e) => {
            const regex = /^[0-9]$/;
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Delete",
            ];

            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          type="text"
          id="available_count"
          name="available_count"
          className={`pro-input lg ${
            getFieldError("available_count") && " error"
          }`}
          {...formik.getFieldProps("available_count")}
          error={getFieldError("available_count")}
          errorMessage={getFieldError("available_count")}
        />
      )}

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={closeModal}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddCoupon;
