import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  start_date: "",
    end_date: "",
    payment_status: "",
  productData: [],
  transactionData: {},
};

export const createNewCoupon = createAsyncThunk(
  "new/createNewCoupon",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-coupon/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCoupon = createAsyncThunk(
  "v1/admin-coupon/update",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-coupon/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCouponDetails = createAsyncThunk(
  "v1/admin-coupon/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin-coupon/show/${id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-review/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-review/delete`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserList = createAsyncThunk(
  "list/users",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-coupon/verified-users");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const exportOrder = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/admin-order/export`,
      params,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error?.response;
  }
};
const ordersSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.start_date= action.payload.start_date;
      state.end_date= action.payload.end_date;
      state.payment_status= action.payload.payment_status;
      
    },
    resetExtraFilters: (state, action) => {
      state.start_date= '';
      state.end_date= '';
      state.payment_status= '';
      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCouponDetails.fulfilled, (state, action) => {
      state.is_edit = true;
      state.selectedItemsDetails = action?.payload?.data?.data;
    });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  ordersSlice.actions;

export default ordersSlice.reducer;
