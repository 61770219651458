import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import usePrivacyPolicy from "./usePrivacyPolicy";
import Style from "./privacyPolicy.module.scss";
import EditPolicy from "./EditPolicy";

const PrivacyPolicy = () => {
  const {
    privacyPolicyData,
    privacyPolicyState,
    refetch,
    handleCloseModal,
    parse,
    handleOpenEditModal,
  } = usePrivacyPolicy();
  return (
    <>
      <HeadingGroup title={"Privacy Policy"} extraClassName={"pro-mb-6"}>
        <button
          className="pro-btn pro-btn-primary pro-items-center"
          onClick={handleOpenEditModal}
        >
          <span className="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
      </HeadingGroup>
      <div className="pro-m-2">
        <p>{parse(privacyPolicyData?.data?.content ?? "")}</p>
      </div>

      <ModalLayout
        centered={false}
        show={privacyPolicyState?.editModal}
        handleClose={handleCloseModal}
        title={"Update Privacy Policy"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle={{ root: Style.modal_root }}
      >
        <div className="modal_content_wrap">
          <EditPolicy closeModal={handleCloseModal} refetch={refetch} />
        </div>
      </ModalLayout>
    </>
  );
};

export default PrivacyPolicy;
