import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../../store/slices/Issues/issuesSlice.js";
import IssueFilterForm from "./IssueFilterForm.js";

const useFilter = ({ setShowform, refetch }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.issues);

  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
  });

  const initialValues = {
    start_date: state.start_date,
    end_date: state.end_date,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { start_date, end_date } = values;

      if (start_date === "" && end_date === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        const obj = {
          start_date: values?.start_date,
          end_date: values?.end_date,
        };

        dispatch(setStoreFormValues(obj));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <IssueFilterForm
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
