import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  useGetCountryListDataQuery,
  useCreatePackagePriceMutation,
  useUpdatePackagePriceMutation,
} from "../../../../store/queries/packages";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getFormatedDate } from "../../../../utils/functions/table";

const usePackagePriceForm = ({ closeModal, refetch }) => {
  const { packagePriceEditData, packagePriceEdit, selectedId } = useSelector(
    (state) => state.packagePrice
  );
  const { packageID } = useParams();
  const { data: countryData = {} } = useGetCountryListDataQuery({});
  const [createPackagePrice] = useCreatePackagePriceMutation();
  const [updatePackagePrice] = useUpdatePackagePriceMutation();
  let currencyList = [
    {
      label: "INR",
      value: "INR",
    },
    // {
    //   label: "AED",
    //   value: "AED",
    // },
    {
      label: "USD",
      value: "USD",
    },
  ];

  const validation = Yup.object({
    country_id: Yup.string().required("*Country  is required"),
    mrp_amount: Yup.string().required("*Mrp Amount  is required"),
    offer_percentage: Yup.string().required(
      "*Offer Percentage Amount  is required"
    ),
    currency: Yup.string().required("*Currency is required"),
    price: Yup.string().required("*Price is required"),
    tax: Yup.string().required("*Tax is required"),
    offer_validity: Yup.string().required("*Offer Validity is required"),
  });
  const formik = useFormik({
    initialValues: {
      country_id: packagePriceEdit
        ? packagePriceEditData?.data?.country_id
        : "",
      mrp_amount: packagePriceEdit
        ? packagePriceEditData?.data?.mrp_amount
        : "",
      offer_percentage: packagePriceEdit
        ? packagePriceEditData?.data?.offer_percentage
        : "",
      currency: packagePriceEdit ? packagePriceEditData?.data?.currency : "",
      price: packagePriceEdit ? packagePriceEditData?.data?.price : "",
      tax: packagePriceEdit ? packagePriceEditData?.data?.tax : "",
      offer_validity: packagePriceEdit
        ? getFormatedDate(packagePriceEditData?.data?.offer_validity)
        : "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const obj = {
        id: +packageID,
        type: 2,
        country_id: values?.country_id,
        mrp_amount: values?.mrp_amount,
        offer_percentage: +values?.offer_percentage,
        currency: values?.currency,
        price: values?.price,
        tax: values?.tax,
        offer_validity: getFormatedDate(values?.offer_validity),
      };

      if (packagePriceEdit) {
        obj.price_id = selectedId;

        updatePackagePrice(obj).then((res) => {
          if (res?.data?.status_code === 200) {
            closeModal();
            refetch();
            toast.success("Price updated successfully");
          } else if (res?.data?.status_code === 422) {
            Object.keys(res.data.errors).forEach((field) => {
              formik.setFieldError(field, res.data.errors[field]);
            });
          } else {
            toast.error("Failed to update price");
            closeModal();
          }
        });
      } else {
        createPackagePrice(obj).then((res) => {
          if (res?.data?.status_code === 200) {
            closeModal();
            refetch();
            toast.success("Price added successfully");
          } else if (res?.data?.status_code === 422) {
            Object.keys(res.data.errors).forEach((field) => {
              formik.setFieldError(field, res.data.errors[field]);
            });
          } else {
            toast.error("Failed to add price");
            closeModal();
          }
        });
      }
    },
  });

  const handleEndDateChange = (e) => {
    formik.setFieldValue("offer_validity", e.target.value);
  };
  return {
    formik,
    countryData,
    currencyList,
    packagePriceEdit,
    handleEndDateChange,
  };
};

export default usePackagePriceForm;
