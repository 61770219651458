import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  createNewCoupon,
  updateConfig,
  updateCoupon,
} from "../../../../store/slices/coupon/couponSlice";

const useAddAddons = ({ refetch, setShowform }) => {
  const dispatch = useDispatch();

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.coupon
  );

  const initialData = {
    available_count: is_edit ? selectedItemsDetails?.available_count : "",
    code: is_edit ? selectedItemsDetails?.code : "",
    discount_percentage: is_edit
      ? selectedItemsDetails?.discount_percentage
      : 10,
    description: is_edit ? selectedItemsDetails?.description : "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      available_count: Yup.number()
        .min(1, "Number must be greater than 0")
        .required("*Required"),
      code: Yup.string().required("*Required"),
      discount_percentage: Yup.number()
        .typeError("Discount percentage must be a number")
        .min(0, "Minimum value is 0")
        .required("*Required"),
      description: Yup.string().required("*Required"),
    }),

    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("available_count", values["available_count"]);
      formData.append("code", values["code"]);
      formData.append("discount_percentage", values["discount_percentage"]);
      formData.append("description", values["description"]);

      if (is_edit) {
        formData.append("id", selectedId);
        dispatch(updateCoupon(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.data?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      } else {
        dispatch(createNewCoupon(formData))
          .then((result) => {
            if (result?.payload?.data?.status_code === 200) {
              refetch();
              toast.success(result?.payload?.data?.message);
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.is_edit = false;
                  state.clearSelection = true;
                })
              );
              setShowform();
            } else {
              toast.error(result?.payload?.message);
            }
          })
          .catch((err) => {
            Object.entries(err.errors).forEach(([fieldName, errorMessage]) => {
              toast.error("Something went wrong!");
            });
          });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    is_edit,
    getFieldError,
    closeModal,
  };
};

export default useAddAddons;
