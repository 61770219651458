import {
  Header,
  MultiColumnTable,
  NavGroup,
  ProfileSideBar,
} from "@wac-ui-dashboard/wac_component_library";
import useOrderDetailLayout from "./useOrderDetailLayout";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import { Link, Outlet } from "react-router-dom";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
// import Style from "./orderDetailLayout.module.scss";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import Assets from "../../../../assets/Assets";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import profileStyle from "./orderDetailLayout.module.scss";

const OrderDetailLayout = () => {
  const {
    isFetching,
    orderDetailsData,
    AddressDetails,
    navigation,
    OrderDetails,
    orderSummary,
  } = useOrderDetailLayout();
  const { navigations, globalState } = useCommonLayout();

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(orderSummary?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div
                    className={`pro-pt-5 pro-d-flex pro-flex-column pro-h-100`}
                  >
                    <div className="pro-px-4 pro-pb-5">
                      <div className={`pro-border-bottom pro-pb-3 `}>
                        <Link to={`/order`} className="pro-back-btn">
                          <span className="material-symbols-outlined">
                            {" "}
                            keyboard_arrow_left{" "}
                          </span>
                          {`Back`}
                        </Link>
                        <h3 className={`h3 pro-ttl pro-mb-0 pro-mt-3`}>
                          #{orderDetailsData?.data?.order_number}
                        </h3>
                      </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Order Details"}
                          data={OrderDetails}
                          extraClassNames={profileStyle.table}
                        />
                      </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <MultiColumnTable
                          title={"Address Details"}
                          data={AddressDetails}
                          extraClassNames={profileStyle.table}
                        />
                      </div>
                    </div>
                    <div
                      className={`pro-mt-auto pro-border-top pro-p-5 pro-d-flex pro-items-center pro-justify-between pro-gap-2 ${profileStyle.total_wrap}`}
                    >
                      <h6 className="pro-ttl pro-mb-0 h6">Total</h6>
                      <h6
                        className={`pro-ttl pro-mb-0 h6 text-truncate ${profileStyle.total_amount}`}
                      >
                        {orderDetailsData?.data?.total_amount}
                      </h6>
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <div className={`${profileStyle.main_container} pro-pt-4 pro-ps-5`}>
            <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default OrderDetailLayout;
