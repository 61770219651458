import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  start_date: "",
  end_date: "",
  type: "",
};

export const createNewQuestionnaire = createAsyncThunk(
  "new/createNewQuestionnaire",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-questionnaire/store`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestionnaire = createAsyncThunk(
  "v1/admin-questionnaire/update",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-questionnaire/update`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuestionnaireDetails = createAsyncThunk(
  "v1/admin-questionnaire/show",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin-questionnaire/show/${id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-questionnaire/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin-questionnaire/delete`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTypeList = createAsyncThunk(
  "list/types",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin-questionnaire/question-type");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.type = action.payload.type;
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
    },
    resetExtraFilters: (state, action) => {
      state.type = "";
      state.start_date = "";
      state.end_date = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestionnaireDetails.fulfilled, (state, action) => {
      state.is_edit = true;
      state.selectedItemsDetails = action?.payload?.data?.data;
    });
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  questionnaireSlice.actions;

export default questionnaireSlice.reducer;
