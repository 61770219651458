import React from "react";
import useTransactionPage from "./useTransactionPage";
import { Table } from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../../Global/EmptyData";

const TransactionPage = () => {
  const { transactionData, isFetching, field, getRow } = useTransactionPage();
  return (
    <>
      {transactionData?.length === 0 ? (
        <EmptyData />
      ) : (
        <Table
          data={transactionData || []}
          uniqueID={"id"}
          showCheckBox={false}
          deletable={false}
          editable={false}
          multiSelect={false}
          assignable={false}
          fields={field}
          getRow={getRow}
          loading={isFetching}
        />
      )}
    </>
  );
};

export default TransactionPage;
