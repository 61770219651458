import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CouponFilterForm from "./CouponFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../../store/slices/coupon/couponSlice";

const useFilter = ({ setShowform, refetch, formData }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.coupon);

  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    status: Yup.string().nullable(),
    created_from: Yup.string().nullable(),
    created_to: Yup.string().nullable()
    // coupon_type: Yup.string().nullable(),
  });

  const initialValues = {
    start_date: state.start_date,
    end_date: state.end_date,
    created_from: state.created_from,
    created_to: state.created_to,
    // coupon_type: state.coupon_type,
    status: state.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { start_date, end_date, status, created_from, created_to } = values;

      if (start_date === "" && end_date === "" && status === "" && created_from === "" && created_to === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        const obj = {
          start_date: values?.start_date,
          end_date: values?.end_date,
          created_from: values?.created_from,
          created_to: values?.created_to,
          status: values?.status !== "" && values?.status?.toString(),
        };

        dispatch(setStoreFormValues(obj));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <CouponFilterForm
        formik={formik}
        handleReset={handleReset}
        formData={formData}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
