import { useSelector, useDispatch } from "react-redux";
import {
  useGetFaqDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/cms";
import { deleteData, faqEditData, updateConfig } from "../../../store/slices/Cms/faqSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";

const UseFaq = () => {
  const dispatch = useDispatch();
  const faqState = useSelector((state) => state.faq);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const {
    data: faqData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetFaqDataQuery({
    sort_by: faqState.sortBy,
    sort_order: faqState.sortOrder,
    search: faqState.search,
    per_page: faqState.currentPageSize,
    page: faqState.currentPage,
  });

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleSort = (label) => {
    if (faqState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = faqState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      status: (field, data) => (
        <p
          className={`pro-badge ${
            data?.status === 1
              ? "badge-success-outline"
              : "badge-pending-outline"
          }`}
        >
          {data?.status === 1 ? "Active" : "Inactive"}
        </p>
      ),
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedItemsDetails = "";
        state.is_edit = false;
      })
    );
  };

  const closeCreateModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleEditAction = (data) => {
    const ID = data?.[0];
    dispatch(faqEditData(ID));
    dispatch(
      updateConfig((state) => {
        state.selectedId = ID;
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };
  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({ id: selectedItemID[0] }))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    faqData,
    faqState,
    isLoading,
    isFetching,
    paginationOptions,
    showEditModal,
    updateTableFields,
    handleEditColumnsClick,
    handleEditAction,
    refetch,
    closeCreateModal,
    handleCreateClick,
    handlePagination,
    handlePageSize,
    getRow,
    handleSort,
    handleSearch,
    handleDelete,
    showDeleteConfirm,
    handleDeleteAction,
    setShowDeleteConfirm
  };
};

export default UseFaq;
