import { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  updateConfig,
  deleteData,
} from "../../../store/slices/Issues/issuesSlice.js";
import {
  useGetListDataQuery,
  useUpdateStatusByIdMutation,
  issues,
} from "../../../store/queries/issues/index.js";
import { toast } from "react-toastify";
import { useGetBasicDataQuery, useUpdateTableFieldsDataMutation } from "../../../store/queries/global/index.js";
import { current } from "@reduxjs/toolkit";
import Select from "react-select";
const useIssues = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.issues);

  const { showCreateModal, selctedId } = useSelector((state) => state.issues);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const { data: formData = {} } = useGetBasicDataQuery();
  const [is_Editable, setIsEditable] = useState(false);

  const tableRef = useRef(null);
  const [filterShow, setFilterShow] = useState(false);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
    start_date: menuState.start_date,
    end_date: menuState.end_date,
  });

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const [updateStatus] = useUpdateStatusByIdMutation();

  const hasUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  const hasStatusUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    const tempResponse = mainData?.data?.data?.data.find((item) => {
      return item.staff_id === data?.[0];
    });
    const tempGender = formData?.data?.gender.find(
      (item) => item?.id === tempResponse.gender.id
    );
    const tempResponseData = {
      branch_details: tempResponse.branch_details,
      name: tempResponse?.name,
      phone_number: tempResponse?.phone_number,
      email: tempResponse?.email,
      country_code: tempResponse?.country_code,
      role_details: tempResponse?.role_details,
      profile_image: tempResponse?.profile_image,
      profile_image_name: tempResponse?.profile_image_name,
      gender: tempGender,
    };

    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = tempResponseData;
        // state.selectedItemsDetails = tempGender;
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        const isChecked = data?.status === "Active" ? true : false;
let statusOptions = [
  { label: "Pending", value: 0 },
  { label: "Solved", value: 1 },
]
        return (
          <div>
            <div >
              {/* <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={() =>
                  hasStatusUpdatePermission
                    ? toggleStatusById(isChecked, data?.staff_id)
                    : undefined
                }
                disabled={!hasStatusUpdatePermission}
              /> */}
              <Select
                id="status"
                name="status"
                placeholder={"Select"}
                className={`pro-input lg`}
                classNamePrefix="pro-input"
                options={statusOptions}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                // value={data?.status}
                value={statusOptions?.filter((m) => data?.status === m.label
                )}
                // onBlur={formik?.handleBlur("status")}
                onChange={(value) => toggleStatusById(value, data?.id)}
                menuPlacement="auto"
                // isClearable
                // isDisabled={certificateList?.length > 0 ? false : true}
                // isMulti
              />
            </div>
          </div>
        )
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const toggleStatusById = (value, id) => {
    let formData = new FormData();
    // formData.append("_method", "PUT");
    formData.append("id", id);
    formData.append("status", value?.value);

    updateStatus(formData);
    dispatch(
      issues.util.updateQueryData(
        "getListData",
        {
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          page_size: menuState.currentPageSize,
          page: menuState.currentPage,
          start_date: menuState.start_date,
          end_date: menuState.end_date,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.rows?.data?.map((res) =>
            res.id === id
              ? {
                  ...res,
                  status: value?.label,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              rows: {
                ...currentCache.data.rows,
                data: newResult,
              },
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/branches");
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const checkIsActive = (id) => {
    if (Array.isArray(id)) {
      let isStaffExist = mainData?.data?.data?.data?.filter(
        (row) => row.staff_id === id[0]
      );
      if (isStaffExist.length > 0 && isStaffExist[0]["status"] === "Deleted") {
        setIsEditable(true);
        return;
      } else {
        setIsEditable(false);
      }
    }
  };

  return {
    is_Editable,
    mainData,
    menuState,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    selectedOption,
    tableRef,
    showDeleteConfirm,
    formData,
    currentPage: menuState.currentPage,
    checkIsActive,
    handleEditAction,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleDelete,
    setShowDeleteConfirm,
    handleDeleteAction,
    setFilterShow,
    filterShow
  };
};

export default useIssues;
