import {
  HeadingGroup,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTermsAndCondition from "./useTermsAndCondition";
import EditTandC from "./editTermsAndConditions";
import Style from "./termsAndCondition.module.scss";

const TermsAndConditions = () => {
  const {
    termsAndConditionsData,
    termsAndConditionState,
    refetch,
    handleOpenEditModal,
    handleCloseModal,
    parse,
  } = useTermsAndCondition();
  return (
    <>
      <HeadingGroup title={"Terms and Condition"} extraClassName={"pro-mb-6"}>
        <button
          className="pro-btn pro-btn-primary pro-items-center"
          onClick={handleOpenEditModal}
        >
          <span className="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
      </HeadingGroup>
      <div className="pro-m-2">
        <p>{parse(termsAndConditionsData?.data?.content ?? "")}</p>
      </div>

      <ModalLayout
        centered={false}
        show={termsAndConditionState?.editModal}
        handleClose={handleCloseModal}
        title={"Update Terms And Conditions"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle={{ root: Style.modal_root }}
      >
        <div className="modal_content_wrap">
          <EditTandC closeModal={handleCloseModal} refetch={refetch} />
        </div>
      </ModalLayout>
    </>
  );
};

export default TermsAndConditions;
