import {
  Button,
  Header,
  Image,
  ModalLayout,
  MultiColumnTable,
  NavGroup,
  ProfileSideBar,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import ProtectRoute from "../../../../utils/components/ProtectRoute/ProtectRoute";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import profileStyle from "./agentDetailsLayout.module.scss";
import useAgentDetailsLayout from "./useAgentDetailsLayout";
import Assets from "../../../../assets/Assets";
import HeaderActions from "../../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../../pages/Layouts/CommonLayout/useCommonLayout";
import { ImageCard } from "../../../Global/ImageCard";
import PayModal from "./PayModal";

const AgentDetailsLayout = () => {
  const {
    activeProfile,
    isFetching,
    agentBasicData,
    basicDetails,
    navigation,
    showImageModal,
    showPayModal,
    handleClosePayButton,
    handleClickPayButton,
    handleEditClick,
    handleViewImage,
    closeImageModal,
    docAction,
  } = useAgentDetailsLayout();

  const { navigations, globalState } = useCommonLayout();

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row g-0 `}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(agentBasicData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5 left-sidebar`}>
                    <div className={`pro-mb-5 `}>
                      <Link
                        to={`/agent`}
                        className="pro-back-btn pro-items-center pro-d-flex pro-items-center pro-mb-2"
                      >
                        <span className="material-symbols-outlined">
                          {" "}
                          keyboard_arrow_left{" "}
                        </span>
                        Back
                      </Link>
                      {/* <h2 className={profileStyle.left_sidebar_title}>{agentBasicData?.data?.module_name}</h2> */}
                    </div>
                    {agentBasicData?.data?.agent_data?.is_verified === 1 && (
                      <div
                        className={`${profileStyle.left_sidebar_sticky_top}`}
                      >
                        <div className={`${profileStyle.profile} pro-mb-4`}>
                          <Image
                            src={`${
                              agentBasicData?.data?.module_image_path ??
                              Assets.GENDER_IMAGE
                            }`}
                            width={100 || ""}
                            height={100 || ""}
                          />
                        </div>
                      </div>
                    )}
                    <div className="pro-border-bottom pro-mb-5 pro-d-flex pro-w-100">
                      <div>
                        <h2 className={profileStyle.left_sidebar_name}>
                          {agentBasicData?.data?.agent_data?.first_name}{" "}
                          {agentBasicData?.data?.agent_data?.last_name}
                        </h2>
                        <p className={profileStyle.left_sidebar_date}>
                          Request on 19 Jun 2023{" "}
                        </p>
                      </div>
                      {/* {agentBasicData?.data?.agent_data?.is_verified === 1 && (
                        <div className="pro-ms-2">
                          <Button
                            className="pro-btn pro-btn-primary"
                            onClick={handleClickPayButton}
                          >
                            Pay
                          </Button>
                        </div>
                      )} */}
                      {/* <p className={profileStyle.left_sidebar_ID}>EXPTZ2024001</p> */}
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <div
                        className={`pro-d-flex pro-pb-3  pro-justify-between pro-border-bottom item_dtls_wrap `}
                      >
                        <MultiColumnTable
                          minWidth={`30%`}
                          title={"Basic Details"}
                          data={basicDetails}
                          extraClassName={``}
                          // editIcon={
                          //     <span className="material-symbols-outlined">
                          //       {basicData?.result?.permissions["can_edit"]  ? `edit_square` : ``}
                          //     </span>
                          // }
                          handleEdit={() => handleEditClick()}
                          extraClassNames={` multi_column_table_item_details`}
                        />
                        {/* {agentBasicData?.data?.agent_data?.is_verified !== 0 && (
                          <span className={`material-symbols-outlined icon_edit `}>
                            edit_square
                          </span>
                        )} */}
                      </div>
                    </div>
                    {agentBasicData?.data?.agent_data?.is_verified === 0 && (
                      <div className={`${profileStyle.dtl_item} pro-mb-3 `}>
                        <strong className="pro-mb-2">Resume</strong>
                        {/* <div className={`${profileStyle.dtls} pro-mt-1 `}>
                          {agentBasicData?.data?.agent_data?.resume?.name ?? ""}
                        </div> */}
                        <div
                          onClick={(e) => handleViewImage()}
                          className={` pro-mb-4 ${profileStyle.resume_file}`}
                        >
                          <div
                            className={`pro-d-flex pro-gap-1 pro-items-center  ${profileStyle.resume_file_inner}`}
                          >
                            <figure className="pro-mb-0">
                              <img
                                src={Assets.ICON_DOC}
                                width={20}
                                height={20}
                                alt={`doc icon`}
                              />
                            </figure>
                            <span>
                              {agentBasicData?.data?.agent_data?.resume?.name ??
                                ""}
                            </span>
                          </div>
                        </div>
                        <div className="pro-d-flex pro-gap-3">
                          <button
                            className={`pro-btn pro-btn-outline lg pro-w-100 ${profileStyle.btn_reject} `}
                            onClick={() => docAction("reject")}
                          >
                            Reject
                          </button>
                          <button
                            className={`pro-btn pro-btn-primary lg pro-w-100 ${profileStyle.btn_accept} `}
                            onClick={() => docAction("accept")}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    )}
                    {agentBasicData?.data?.agent_data?.is_verified !== 0 && (
                      <div
                        className={`${profileStyle.dtl_item} pro-mb-3 pro-mt-4 pro-d-flex pro-justify-between`}
                      >
                        <strong>Status</strong>
                        {/* <div className={`${profileStyle.dtls} pro-mt-1 `}>
                          {agentBasicData?.data?.agent_data?.resume?.name ?? ""}
                        </div> */}
                        <div>
                          <span className="pro-badge badge-success-outline">
                            {agentBasicData?.data?.agent_data?.is_verified === 1
                              ? "APPROVED"
                              : "REJECTED"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              {agentBasicData?.data?.agent_data?.is_verified === 0 && (
                <>
                  <h6 className={profileStyle.qa_title}>Questions & Answers</h6>
                  <div className={profileStyle.qa_wrap}>
                    <ol className={`pro-ps-0 ${profileStyle.qa_listing_wrap}`}>
                      {agentBasicData?.data?.question?.map((item, index) => (
                        <li
                          key={index}
                          className={` pro-py-4 pro-border-bottom`}
                        >
                          <h6 className="pro-lh-base pro-fw-bold pro-m-0">
                            {item?.question}
                          </h6>
                          <article className="pro-pt-4">{item?.answer}</article>
                        </li>
                      ))}
                    </ol>
                  </div>
                </>
              )}

              {agentBasicData?.data?.agent_data?.is_verified === 1 && (
                <>
                  <ul
                    className={`pro-d-flex pro-mb-4 ${profileStyle.status_bar}`}
                  >
                    <li>
                      <span>Students Registered</span>
                      <strong>
                        {agentBasicData?.data?.counts?.total_user_registered}
                      </strong>
                    </li>
                    <li>
                      <span>Total Programs Sold</span>
                      <strong>
                        {agentBasicData?.data?.counts?.total_orders}
                      </strong>
                    </li>
                    <li>
                      <span>Total Sales Amount</span>
                      <strong>
                        ₹ {agentBasicData?.data?.counts?.total_sales}
                      </strong>
                    </li>
                    <li>
                      <span>Total Commission</span>
                      <strong>
                        ₹ {agentBasicData?.data?.counts?.total_commission}
                      </strong>
                    </li>
                  </ul>
                  <div className={` tab_wrapper`}>
                    <NavGroup
                      navigations={navigation}
                      type={"type-2"}
                      Link={Link}
                    />
                    <Outlet context={{ activeProfile }} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalLayout
        show={showImageModal}
        handleClose={closeImageModal}
        backdrop="static"
      >
        <ImageCard
          data={agentBasicData?.data?.agent_data?.resume?.url}
          handleClose={closeImageModal}
        />
      </ModalLayout>

      <ModalLayout show={showPayModal} handleClose={handleClosePayButton}>
        <PayModal />
      </ModalLayout>
    </ProtectRoute>
  );
};

export default AgentDetailsLayout;
