import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../../store/slices/Campaigns/campaignsSlice.js";
import CampaignFilterForm from "./CampaignFilterForm";

const useFilter = ({ setShowform, refetch, formData }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.campaigns);

  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    utm_source: Yup.string().nullable(),
    utm_medium: Yup.string().nullable(),
    utm_campaign: Yup.string().nullable(),
    utm_term: Yup.string().nullable(),
    
  });

  const initialValues = {
    start_date: state?.start_date,
    end_date: state?.end_date,
    utm_source: state?.utm_source,
    utm_medium: state?.utm_medium,
    utm_campaign: state?.utm_campaign,
    utm_term: state?.utm_term
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { start_date, end_date, utm_source, utm_medium, utm_campaign, utm_term} = values;

      if (start_date === "" && end_date === "" && utm_source === "" && utm_medium === "" && utm_campaign === "" && utm_term === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }

      if (values) {
        const obj = {
          start_date: values?.start_date,
          end_date: values?.end_date,
          utm_campaign: values?.utm_campaign,
          utm_term: values?.utm_term,
          utm_source: values?.utm_source !== "" ? values?.utm_source?.toString() : "",
          utm_medium: values?.utm_medium !== "" ? values?.utm_medium?.toString() : "",
        };

        dispatch(setStoreFormValues(obj));
      }
    },
  });

  const renderTabContent = () => {
    return (
      <CampaignFilterForm
        formik={formik}
        handleReset={handleReset}
        formData={formData}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
