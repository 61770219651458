import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useCms = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Privacy Policy",
      link: "/cms/privacy-policy",
      icon: <span className="material-symbols-outlined x4"> policy </span>,
      active: checkIfActiveRoute("/cms/privacy-policy", true),
    },
    {
      title: "Terms And Condition",
      link: "/cms/terms-and-condition",
      icon: <span className="material-symbols-outlined x4">gavel</span>,
      active: checkIfActiveRoute("/cms/terms-and-condition", true),
    },
    {
      title: "Faq",
      link: "/cms/faq",
      icon: <span className="material-symbols-outlined x4">quiz</span>,
      active: checkIfActiveRoute("/cms/faq", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.sub_menu_permissions?.map((side_menu) => side_menu.sub_menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenuPermission };
};

export default useCms;
