import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  clearSelection: false,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
};

const programSessionSlice = createSlice({
  name: "programSession",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getSessionDetails.pending, (state, action) => {
      //      state.isTravelLoading = true;
      //    })
      .addCase(getSessionDetails.fulfilled, (state, action) => {
        state.is_edit = true;
        state.selectedItemsDetails = action?.payload?.data?.data;
      });
    // .addCase(getSessionDetails.rejected, (state, action) => {
    //   state.isTravelLoading = false;
    // })
  },
});

export const createNewSession = createAsyncThunk(
  "new/createNewSession",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin-module/store-program-sessions`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSession = createAsyncThunk(
  "new/updateSeession",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin-module/session-update`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSessionDetails = createAsyncThunk(
  "v1/admin-module/session-content",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin-module/session-content/${id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "/v1/admin-module/delete-session",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin-module/delete-session`,
        params
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const { updateConfig } = programSessionSlice.actions;

export default programSessionSlice.reducer;
