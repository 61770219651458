import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedId: "",
  selectedItemsDetails: "",
  is_edit: false,
  start_date: "",
  end_date: "",
  utm_medium: "",
      utm_source: "",
      utm_term: "",
      utm_campaign: "",
};

export const exportCampaign = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/v1/admin-campaign/export`, {
      params, // Send parameters as query string
      responseType: "blob",
    });

    return response;
  } catch (error) {
    return error?.response;
  }
};


const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.start_date= action.payload.start_date;
      state.end_date= action.payload.end_date;
      state.utm_medium= action.payload.utm_medium;
      state.utm_source= action.payload.utm_source;
      state.utm_campaign= action.payload.utm_campaign;
      state.utm_term= action.payload.utm_term;
    },
    resetExtraFilters: (state, action) => {
      state.start_date= '';
      state.end_date= '';
      state.utm_medium= '';
      state.utm_source= '';
      state.utm_term= '';
      state.utm_campaign= '';
    },
  }
});

export const { updateConfig, setStoreFormValues, resetExtraFilters} =
  campaignsSlice.actions;

export default campaignsSlice.reducer;
