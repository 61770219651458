import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateTermsAndConditions } from "../api";
import { updateConfig } from "../../../../store/slices/Cms/termsAndConditionSlice";
import { useEffect } from "react";
import DOMPurify from "dompurify";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const useEditTandC = ({ refetch }) => {
  const dispatch = useDispatch();
  const { description } = useSelector((state) => state.termsAndCondition);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      const plainText = values?.description
        ?.getCurrentContent()
        ?.getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText?.trim()) {
        errors.description = "*Description cannot be empty";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const contentState = values.description.getCurrentContent();
      const contentRaw = convertToRaw(contentState ?? "");
      const plainText = draftToHtml(contentRaw ?? "");

      let obj = {
        content: plainText,
      };
      updateTermsAndConditions(obj).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.editModal = false;
            })
          );
          toast.success("Privacy policy updated");
          refetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    const desc = setTimeout(() => {
      //setting up description
      const htmlContent = description?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify?.sanitize(htmlContent);
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState?.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      formik.setFieldValue("description", editorState);
    }, 1000);
    return () => clearTimeout(desc);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  const handleContentChange = (value) => {
    formik.setFieldValue("description", value);
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.editModal = false)));
  };
  return {
    formik,
    handleCloseModal,
    handleContentChange,
  };
};

export default useEditTandC;
