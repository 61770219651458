import { useSelector } from "react-redux";
import { useGetTransactionDetailDataQuery } from "../../../../store/queries/order";
import { useParams } from "react-router-dom";

const useTransactionPage = () => {
  const { orderID } = useParams();
  const currentURL = window.location.href;
  const newURL = currentURL.replace("/transaction", "");

  window.addEventListener("beforeunload", function (e) {
    // Redirect to the desired URL
    e.preventDefault();
    // Set the new URL in the window location
    window.location.href = newURL;
  });

  const { data: transactionList = {}, isFetching } =
    useGetTransactionDetailDataQuery({
      order_id: orderID,
    });

  const fieldData = transactionList?.data?.field;

  const field = fieldData && Object.fromEntries(
    Object.entries(fieldData).map(([key, value]) => [
      key,
      { label: value?.label, position: value?.position },
    ])
  );

  const getRow = (field, data) => {
    const rows = {
      name: (field, data) => (
        <p className="pro-mb-0">
          <span className="pro-me-1">{data[field]}</span>
          <span
            className={`pro-badge ${
              data?.type === "Package"
                ? "badge-success-outline"
                : "badge-warning-outline"
            }`}
          >
            {data?.type}
          </span>
        </p>
      ),

      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    transactionData: transactionList?.data?.rows,
    isFetching,
    field,
    getRow,
  };
};

export default useTransactionPage;
